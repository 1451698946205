import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as authAction from '../actions/auth';
import useForm from '../hooks/useForm';

const initialFieldValues = {
  name: '',
  email: '',
  password: '',
  password2: '',
};

const RegisterPage = ({ registerUser, errors }) => {
  const { values, handleInputChange } = useForm(initialFieldValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    registerUser(values);
  };

  return (
    <div className="hero is-fullheight has-background-white-bis has-padding-top-20 has-padding-bottom-20 has-padding-50-tablet">
      <div className="container is-fluid">
        <div className="columns is-centered">
          <div className="column is-6-tablet is-4-desktop">
            <h1 className="title">Register</h1>

            <div className="box">
              {(!!errors.name ||
                !!errors.email ||
                !!errors.password ||
                !!errors.password2) && (
                <div className="notification is-danger is-light">
                  {errors.name ||
                    errors.email ||
                    errors.password ||
                    errors.password2}
                </div>
              )}

              <form noValidate onSubmit={handleSubmit}>
                <div className="field">
                  <label className="label" htmlFor="name">
                    Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      onChange={handleInputChange}
                      value={values.name}
                      id="name"
                      type="text"
                      name="name"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="email">
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      onChange={handleInputChange}
                      value={values.email}
                      id="email"
                      type="email"
                      name="email"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="password">
                    Password
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      onChange={handleInputChange}
                      value={values.password}
                      id="password"
                      type="password"
                      name="password"
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label" htmlFor="password2">
                    Confirm Password
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      onChange={handleInputChange}
                      value={values.password2}
                      id="password2"
                      type="password"
                      name="password2"
                    />
                  </div>
                </div>

                <div className="has-margin-top-30">
                  <button
                    type="submit"
                    className="button is-fullwidth is-primary"
                  >
                    Register
                  </button>
                </div>

                <hr />

                <Link
                  to="/login"
                  className="button is-fullwidth is-primary is-outlined"
                >
                  Login
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: state.auth.errors,
});

const mapActionToProps = {
  registerUser: authAction.registerUser,
};

export default connect(mapStateToProps, mapActionToProps)(RegisterPage);
