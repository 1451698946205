import { UI_SHOW_MODAL, UI_CLOSE_MODAL } from './types';

export const showModal = (modalProps) => (dispatch) => {
  dispatch({
    type: UI_SHOW_MODAL,
    payload: modalProps,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: UI_CLOSE_MODAL,
  });
};
