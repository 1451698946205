import React from 'react';

import Layout from '../components/Layout';

import TimeRecentList from '../components/TimeRecentList';

const IndexPage = () => {
  return (
    <Layout>
      <TimeRecentList />
    </Layout>
  );
};

export default IndexPage;
