import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import uuid from 'react-uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStop,
  faPlay,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as timeAction from '../actions/time';
import {
  extractDate,
  extractTime,
  getEndTime,
  parseDuration,
  parseDurationObj,
} from '../helpers';

const TimeItem = ({
  deleteTime,
  time,
  updateTime,
  currentTime,
  user,
  createTime,
}) => {
  const [isEditing, setEditing] = useState(false);

  const initialValues = {
    date: new Date(time.startTime),
    startHour: new Date(time.startTime).getHours(),
    startMin: new Date(time.startTime).getMinutes(),
    duration: time.duration,
  };

  const [values, setValues] = useState({ ...initialValues });

  const handleSuccess = () => {};

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this record?'))
      deleteTime(time._id, handleSuccess);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setValues({
      ...values,
      date,
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const startTime = new Date(values.date);
    startTime.setHours(values.startHour);
    startTime.setMinutes(values.startMin);

    const formData = {
      startTime,
      duration: values.duration,
    };

    updateTime(time._id, formData, () => {
      setEditing(false);
    });
  };

  const handleCancel = () => {
    setEditing(false);
    setValues(initialValues);
  };

  const handleStop = () => {
    const duration = new Date() - new Date(currentTime.startTime);
    const newTime = {
      duration,
    };

    updateTime(currentTime._id, newTime, handleSuccess);
  };

  const handleStart = () => {
    if (currentTime._id) handleStop();

    const newTime = {
      startTime: new Date(),
      user: user.id,
      task: time.task._id,
    };

    createTime(newTime, handleSuccess);
  };

  const handleDurationChange = (e) => {
    const { name, value } = e.target;

    let nextDuration = values.duration;

    if (name === 'durationHour')
      nextDuration =
        parseInt(value, 10) * (1000 * 60 * 60) +
        parseDurationObj(values.duration).m * (1000 * 60);

    if (name === 'durationMin')
      nextDuration =
        parseDurationObj(values.duration).h * (1000 * 60 * 60) +
        parseInt(value, 10) * (1000 * 60);

    setValues({
      ...values,
      duration: nextDuration,
    });
  };

  return (
    <>
      <tr>
        <td>
          <b>
            <Link to={`/project/${time.task.project._id}`}>
              {time.task.project.name}
            </Link>{' '}
            - <Link to={`/task/${time.task._id}`}>{time.task.name}</Link>
          </b>
        </td>
        <td>
          {extractDate(time.startTime)} - {extractTime(time.startTime)} -{' '}
          {getEndTime(time.startTime, time.duration)}
        </td>

        <td>{parseDuration(time.duration)}</td>
        <td className="has-text-right">
          {currentTime && currentTime._id === time._id ? (
            <button
              type="button"
              onClick={handleStop}
              className="button is-small is-rounded is-info mr-2"
              aria-label="Stop"
            >
              <FontAwesomeIcon icon={faStop} />
            </button>
          ) : (
            <>
              <button
                type="button"
                onClick={() => setEditing(!isEditing)}
                className="button is-small is-rounded mr-2"
                aria-label="edit"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>

              <button
                type="button"
                onClick={handleStart}
                className="button is-small is-rounded is-info mr-2"
                aria-label="Start"
              >
                <FontAwesomeIcon icon={faPlay} />
              </button>
            </>
          )}
        </td>
      </tr>
      {!!isEditing && (
        <tr className="has-background-white-ter">
          <td colSpan="4">
            <form onSubmit={handleEdit} className="is-flex">
              <div className="mr-2">
                <label
                  htmlFor="date"
                  className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1"
                >
                  Date
                </label>
                <DatePicker
                  selected={values.date}
                  onChange={handleDateChange}
                  name="date"
                  dateFormat="d MMMM yyyy"
                  customInput={
                    <input
                      id="date"
                      className="input is-small"
                      type="text"
                      placeholder="Text input"
                    />
                  }
                />
              </div>
              <div className="mr-4">
                <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
                  Start
                </div>

                <div className="is-flex">
                  <div className="select is-small">
                    <select
                      name="startHour"
                      onChange={handleInputChange}
                      value={values.startHour}
                    >
                      {[...Array(24)].map((e, i) => (
                        <option key={uuid()}>
                          {i < 10 && '0'}
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>

                  <span className="px-1">:</span>

                  <div className="select is-small">
                    <select
                      name="startMin"
                      onChange={handleInputChange}
                      value={values.startMin}
                    >
                      {[...Array(60)].map((e, i) => (
                        <option key={uuid()}>
                          {i < 10 && '0'}
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="mr-4">
                <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
                  Duration
                </div>

                <div className="is-flex">
                  <div className="select is-small">
                    <select
                      name="durationHour"
                      onChange={handleDurationChange}
                      value={parseDurationObj(values.duration).h}
                    >
                      {[...Array(24)].map((e, i) => (
                        <option key={uuid()}>
                          {i < 10 && '0'}
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>

                  <span className="px-1">:</span>

                  <div className="select is-small">
                    <select
                      name="durationMin"
                      onChange={handleDurationChange}
                      value={parseDurationObj(values.duration).m}
                    >
                      {[...Array(60)].map((e, i) => (
                        <option key={uuid()}>
                          {i < 10 && '0'}
                          {i}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="has-margin-left-auto has-margin-top-auto">
                <button
                  type="submit"
                  className="button is-small is-rounded is-primary mr-2"
                >
                  Save
                </button>

                <button
                  type="button"
                  onClick={handleCancel}
                  className="button is-small is-rounded is-secondary mr-2"
                >
                  Cancel
                </button>

                <button
                  type="button"
                  onClick={handleDelete}
                  className="button is-small is-rounded is-danger mr-2"
                  aria-label="delete"
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            </form>
          </td>
        </tr>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentTime: state.time.currentTime,
  user: state.auth.user,
});

const mapActionToProps = {
  createTime: timeAction.createTime,
  deleteTime: timeAction.deleteTime,
  updateTime: timeAction.updateTime,
};

export default connect(mapStateToProps, mapActionToProps)(TimeItem);
