import React from 'react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import * as auth from '../actions/auth';

const Navbar = ({ logoutUser }) => {
  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <NavLink to="/" className="navbar-item">
            TIMER APP
          </NavLink>

          <button
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            type="button"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>

        <div id="navbar" className="navbar-menu">
          <div className="navbar-start">
            <NavLink
              exact
              to="/"
              className="navbar-item"
              activeClassName="is-active"
            >
              Home
            </NavLink>

            <NavLink
              to="/project"
              className="navbar-item"
              activeClassName="is-active"
            >
              Projects
            </NavLink>

            <NavLink
              to="/report"
              className="navbar-item"
              activeClassName="is-active"
            >
              Reports
            </NavLink>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button
                  className="button is-light"
                  type="button"
                  onClick={logoutUser}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapActionToProps = {
  logoutUser: auth.logoutUser,
};

export default connect(null, mapActionToProps)(Navbar);
