import axios from 'axios';

import { setAuthToken } from '../helpers/auth';

import store from './store';

import STORAGE from '../helpers/storage';

const baseUrl = `${process.env.REACT_APP_API_ORIGIN}`;

const api = {
  auth(url = `${baseUrl}auth/`) {
    return {
      loginUser: (userData) => axios.post(`${url}login`, userData),
      registerUser: (userData) => axios.post(`${url}register`, userData),
      logoutUser: (refreshToken) => axios.post(`${url}logout`, refreshToken),
      renewToken: (refreshToken) => axios.post(`${url}token`, refreshToken),
    };
  },

  user(url = `${baseUrl}user/`) {
    return {
      login: () => axios.get(`${url}login`),
    };
  },

  project(url = `${baseUrl}project/`) {
    return {
      fetchAll: () => axios.get(url),
      fetchById: (id) => axios.get(url + id),
      fetchAllWithTasks: () => axios.get(`${url}tasks`),
      create: (newRecord) => axios.post(url, newRecord),
      update: (id, updatedRecord) => axios.put(url + id, updatedRecord),
      delete: (id) => axios.delete(url + id),
    };
  },

  task(url = `${baseUrl}task/`) {
    return {
      fetchAll: () => axios.get(url),
      fetchById: (id) => axios.get(url + id),
      fetchByProject: (id) => axios.get(`${url}project/${id}`),
      create: (newRecord) => axios.post(url, newRecord),
      update: (id, updatedRecord) => axios.put(url + id, updatedRecord),
      delete: (id) => axios.delete(url + id),
    };
  },

  time(url = `${baseUrl}time/`) {
    return {
      fetchAll: () => axios.get(url),
      fetchCurrent: (id) => axios.get(`${url}current/${id}`),
      fetchRecent: (id) => axios.get(`${url}recent/${id}`),
      fetchByUser: (id) => axios.get(`${url}user/${id}`),
      fetchByTask: (id) => axios.get(`${url}task/${id}`),
      create: (newRecord) => axios.post(url, newRecord),
      update: (id, updatedRecord) => axios.put(url + id, updatedRecord),
      delete: (id) => axios.delete(url + id),
    };
  },

  report(url = `${baseUrl}report/`) {
    return {
      byUser: (id, startDate, endDate) =>
        axios.get(`${url}user/${id}/${startDate}/${endDate}`),
    };
  },
};

export default api;

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const onAccessTokenFetched = (accessToken) => {
  subscribers.forEach((callback) => callback(accessToken));
  subscribers = [];
};

const addSubscriber = (callback) => {
  subscribers.push(callback);
};

const renewToken = async (refreshToken) => {
  return api
    .auth()
    .renewToken({ token: refreshToken })
    .then((res) => {
      const { accessToken } = res.data;

      STORAGE.setItem('jwtToken', accessToken);

      return accessToken;
    })
    .catch(() => {
      throw new Error('Unable to renew token');
    });
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const errorResponse = err.response;

    if (errorResponse.status !== 401) throw err;

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      renewToken(STORAGE.getItem('jwtRefreshToken'))
        .then((accessToken) => {
          setAuthToken(accessToken, 'ren');
          onAccessTokenFetched(accessToken);
          isAlreadyFetchingAccessToken = false;
        })
        .catch(() => {
          STORAGE.clear();

          return store.dispatch({
            type: 'USER_LOGOUT',
          });
        });
    }

    return retryOriginalRequest;
  },
);
