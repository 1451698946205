import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../actions/ui';

const ModalWrapper = ({ title, children, closeModalHandler }) => (
  <div className="modal is-active">
    <div className="modal-background" />

    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        <button
          className="delete"
          aria-label="close"
          onClick={closeModalHandler}
          type="button"
        />
      </header>

      {children}
    </div>
  </div>
);

const mapActionToProps = {
  closeModalHandler: closeModal,
};

export default connect(null, mapActionToProps)(ModalWrapper);
