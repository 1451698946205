import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop, faPlay, faEdit } from '@fortawesome/free-solid-svg-icons';
import { fetchById } from '../actions/task';
import { createTime, updateTime } from '../actions/time';
import { showModal } from '../actions/ui';
import { parseDuration } from '../helpers';

const TaskItemHeader = ({
  fetchById,
  taskItem,
  taskId,
  createTime,
  currentTime,
  updateTime,
  user,
  showModal,
}) => {
  useEffect(() => {
    fetchById(taskId);
  }, [taskId, fetchById]);

  const handleStop = () => {
    const duration = new Date() - new Date(currentTime.startTime);
    const newTime = {
      duration,
    };

    updateTime(currentTime._id, newTime);
  };

  const handleStart = () => {
    if (currentTime._id) handleStop();

    const newTime = {
      startTime: new Date(),
      user: user.id,
      task: taskId,
    };

    createTime(newTime);
  };

  const showTaskModal = () => {
    const modal = {
      type: 'TASK_EDIT_MODAL',
      task: taskItem,
    };

    showModal(modal);
  };

  return (
    taskItem && (
      <TaskItemHeaderComponent
        taskItem={taskItem}
        currentTime={currentTime}
        editHandler={showTaskModal}
        startHandler={handleStart}
        stopHandler={handleStop}
      />
    )
  );
};

const TaskItemHeaderComponent = ({
  taskItem,
  currentTime,
  editHandler,
  startHandler,
  stopHandler,
}) => (
  <div className="box">
    <Link to={`/project/${taskItem.projects[0]._id}`}>
      <b>{taskItem.projects[0].name}</b>
    </Link>

    <div className="is-flex align-items-center">
      <b className="has-text-weight-bold is-size-4">{taskItem.name}</b>

      <div className="is-flex has-margin-left-auto">
        <button
          type="button"
          className="button is-small is-rounded ml-2"
          aria-label="Edit"
          onClick={editHandler}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>

        {currentTime._id && currentTime.task._id === taskItem._id ? (
          <button
            type="button"
            onClick={stopHandler}
            className="button is-small is-rounded is-info ml-2"
            aria-label="Stop"
          >
            <FontAwesomeIcon icon={faStop} />
          </button>
        ) : (
          <button
            type="button"
            onClick={startHandler}
            className="button is-small is-rounded is-info ml-2"
            aria-label="Start"
          >
            <FontAwesomeIcon icon={faPlay} />
          </button>
        )}
      </div>
    </div>
    <div className="is-family-code is-size-7">
      Total time: {parseDuration(taskItem.duration)}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  taskItem: state.task.taskItem,
  user: state.auth.user,
  currentTime: state.time.currentTime,
});

const mapActionToProps = {
  fetchById,
  createTime,
  updateTime,
  showModal,
};

export default connect(mapStateToProps, mapActionToProps)(TaskItemHeader);
