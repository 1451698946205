import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop, faPlay } from '@fortawesome/free-solid-svg-icons';
import * as time from '../actions/time';

import { parseDuration } from '../helpers';

const TaskItem = ({ task, user, createTime, updateTime, currentTime }) => {
  const handleSuccess = () => {
    // console.log('success');
  };

  const handleStop = () => {
    const duration = new Date() - new Date(currentTime.startTime);
    const newTime = {
      duration,
    };

    updateTime(currentTime._id, newTime, handleSuccess);
  };

  const handleStart = () => {
    if (currentTime._id) handleStop();

    const newTime = {
      startTime: new Date(),
      user: user.id,
      task: task._id,
    };

    createTime(newTime, handleSuccess);
  };

  return (
    <tr>
      <td>
        <Link to={`/task/${task._id}`}>
          <b>{task.name}</b>
        </Link>
      </td>

      <td>
        <div className="is-flex align-items-center justify-flex-end">
          <span className="mr-2">
            {parseDuration(task.duration) || '00:00'}
          </span>

          {currentTime &&
          currentTime.task &&
          currentTime.task._id === task._id ? (
            <button
              type="button"
              onClick={handleStop}
              className="button is-small is-rounded is-info"
              aria-label="Stop"
            >
              <FontAwesomeIcon icon={faStop} />
            </button>
          ) : (
            <button
              type="button"
              onClick={handleStart}
              className="button is-small is-rounded is-info"
              aria-label="Start"
            >
              <FontAwesomeIcon icon={faPlay} />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

const mapActionToProps = {
  createTime: time.createTime,
  updateTime: time.updateTime,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentTime: state.time.currentTime,
});

export default connect(mapStateToProps, mapActionToProps)(TaskItem);
