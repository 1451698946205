import React, { useEffect, useState, useCallback } from 'react';

import { connect } from 'react-redux';
import * as timeAction from '../actions/time';

import TimeItem from './TimeItem';

import { parseDuration } from '../helpers';

const TimeRecentList = ({ fetchRecent, timeRecentList, user }) => {
  const [times, setTimes] = useState([]);

  const fetchRecentCallback = useCallback(() => {
    fetchRecent(user.id);
  }, [fetchRecent, user]);

  useEffect(() => {
    fetchRecentCallback();
  }, [fetchRecentCallback]);

  useEffect(() => {
    const result = [
      ...timeRecentList
        .reduce((hash, { ...time }) => {
          const date = new Date(time.startTime).setHours(0, 0, 0, 0);

          const current = hash.get(date) || {
            day: date,
            times: [],
            duration: 0,
          };

          current.duration += time.duration ? time.duration : 0;

          current.times.push({ ...time });

          return hash.set(date, current);
        }, new Map())
        .values(),
    ];

    setTimes([...result]);
  }, [timeRecentList]);

  const dateStr = (date) => {
    const nextDate = new Date(date);

    const month = new Intl.DateTimeFormat('en-EN', { month: 'long' }).format(
      nextDate,
    );

    return `${nextDate.getDate()} ${month} ${nextDate.getFullYear()}`;
  };

  return (
    <div className="box">
      {times
        .sort((a, b) => (a.day < b.day ? 1 : -1))
        .map((day) => {
          return (
            <div key={day.day} className="pb-6">
              <div className="has-text-weight-bold is-size-4 px-3">
                {dateStr(day.day)} - {parseDuration(day.duration)}
              </div>

              <div className="mt-2">
                <table className="table is-fullwidth is-hoverable">
                  <tbody>
                    {day.times
                      .sort((a, b) =>
                        Date.parse(a.startTime) < Date.parse(b.startTime)
                          ? 1
                          : -1,
                      )
                      .map((timeItem) => {
                        return <TimeItem time={timeItem} key={timeItem._id} />;
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const mapActionToProps = {
  fetchRecent: timeAction.fetchRecent,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  timeRecentList: state.time.timeList,
});

export default connect(mapStateToProps, mapActionToProps)(TimeRecentList);
