import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';
import uuid from 'react-uuid';

import { closeModal } from '../actions/ui';
import { updateTime, deleteTime } from '../actions/time';

import ModalWrapper from './ModalWrapper';

import 'react-datepicker/dist/react-datepicker.css';

import { parseDurationObj } from '../helpers';

const TaskEditModal = ({ modal, updateTime, deleteTime, closeModal }) => {
  const [values, setValues] = useState({
    date: new Date(modal.timeItem.startTime).setHours(0, 0, 0, 0),
    start: new Date(modal.timeItem.startTime),
    end: new Date(
      Date.parse(modal.timeItem.startTime) + modal.timeItem.duration,
    ),
  });

  const handleSubmit = () => {
    const formData = {
      startTime: values.startTime,
      duration: values.end - values.start,
    };

    updateTime(modal.timeItem._id, formData, closeModal);
  };

  const handleDelete = () => {
    if (!modal.timeItem._id) return;

    if (window.confirm('Are you sure you want to delete this task?'))
      deleteTime(modal.timeItem._id, closeModal);
  };

  const handleDateChange = (date) => {
    setValues({
      ...values,
      date,
    });
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    const nextStart = values.start;
    if (name === 'startHour') nextStart.setHours(value);
    if (name === 'startMin') nextStart.setMinutes(value);

    const nextEnd = values.end;
    if (name === 'endHour') nextEnd.setHours(value);
    if (name === 'endMin') nextEnd.setMinutes(value);

    if (name === 'durationHour')
      nextEnd.setHours(values.start.getHours() + parseInt(value, 10));
    if (name === 'durationMin')
      nextEnd.setMinutes(values.start.getMinutes() + parseInt(value, 10));

    setValues((initValues) => ({
      ...initValues,
      start: nextStart,
      end: nextEnd,
    }));
  };

  return (
    <TaskEditModalComponent
      values={values}
      dateChangeHandler={handleDateChange}
      timeChangeHandler={handleTimeChange}
      submitHandler={handleSubmit}
      deleteHandler={handleDelete}
      closeModalHandler={closeModal}
    />
  );
};

const TaskEditModalComponent = ({
  values,
  dateChangeHandler,
  timeChangeHandler,
  submitHandler,
  deleteHandler,
  closeModalHandler,
}) => (
  <ModalWrapper title="Edit task">
    <section className="modal-card-body" style={{ minHeight: '340px' }}>
      <div className="mb-4">
        <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
          Date
        </div>
        <DatePicker
          selected={values.date}
          name="date"
          dateFormat="d MMMM yyyy"
          onChange={dateChangeHandler}
          customInput={
            <input
              id="date"
              className="input"
              type="text"
              placeholder="Text input"
            />
          }
        />
      </div>

      <div className="is-flex">
        <div className="mr-4">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Start
          </div>

          <div className="is-flex">
            <div className="select">
              <select
                name="startHour"
                value={values.start.getHours()}
                onChange={timeChangeHandler}
              >
                {[...Array(24)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <span className="px-1">:</span>

            <div className="select">
              <select
                name="startMin"
                value={values.start.getMinutes()}
                onChange={timeChangeHandler}
              >
                {[...Array(60)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mr-4">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Finish
          </div>

          <div className="is-flex">
            <div className="select">
              <select
                name="endHour"
                value={values.end.getHours()}
                onChange={timeChangeHandler}
              >
                {[...Array(24)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <span className="px-1">:</span>

            <div className="select">
              <select
                name="endMin"
                value={values.end.getMinutes()}
                onChange={timeChangeHandler}
              >
                {[...Array(60)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mr-4">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Duration
          </div>

          <div className="is-flex">
            <div className="select">
              <select
                name="durationHour"
                value={parseInt(
                  parseDurationObj(values.end - values.start).h,
                  10,
                )}
                onChange={timeChangeHandler}
              >
                {[...Array(24)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <span className="px-1">:</span>

            <div className="select">
              <select
                name="durationMin"
                value={parseInt(
                  parseDurationObj(values.end - values.start).m,
                  10,
                )}
                onChange={timeChangeHandler}
              >
                {[...Array(60)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer className="modal-card-foot">
      <button
        type="button"
        className="button is-success"
        onClick={submitHandler}
      >
        Save changes
      </button>

      <button type="button" className="button" onClick={closeModalHandler}>
        Cancel
      </button>

      <button
        type="button"
        className="button has-margin-left-auto is-danger"
        onClick={deleteHandler}
      >
        Delete task
      </button>
    </footer>
  </ModalWrapper>
);

const mapActionToProps = {
  updateTime,
  deleteTime,
  closeModal,
};

export default connect(null, mapActionToProps)(withRouter(TaskEditModal));
