import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from '@fortawesome/free-solid-svg-icons';
import * as time from '../actions/time';
import { extractTime, parseDuration } from '../helpers';

const TimeCurrent = ({ user, currentTime, fetchCurrentTime, updateTime }) => {
  const [time, setTime] = useState();
  const [timer, setTimer] = useState();

  const fetchCurrentTimeCallback = useCallback(() => {
    fetchCurrentTime(user.id);
  }, [fetchCurrentTime, user]);

  useEffect(() => {
    fetchCurrentTimeCallback();
  }, [fetchCurrentTimeCallback]);

  const handleSuccess = () => {
    // console.log('success');
  };

  const handleStop = () => {
    const duration = new Date() - new Date(currentTime.startTime);
    const newTime = {
      duration,
    };

    clearInterval(timer);

    updateTime(currentTime._id, newTime, handleSuccess);
  };

  const runTimer = useCallback((startTime) => {
    const nextTimer = setInterval(() => {
      const nextTime = parseDuration(Date.now() - Date.parse(startTime));

      setTime(nextTime);
    }, 1000);

    setTimer(nextTimer);
  }, []);

  useEffect(() => {
    if (!currentTime._id) return;

    runTimer(currentTime.startTime);
  }, [currentTime, runTimer]);

  useEffect(
    () => () => {
      clearInterval(timer);
    },
    [timer],
  );

  return (
    <>
      {currentTime._id && (
        <div className="box has-background-info has-text-white">
          <div className="level">
            <div>
              <div className="is-size-4">
                <b>
                  {currentTime.task.project.name} - {currentTime.task.name}
                </b>
              </div>

              <div className="is-family-code">
                Started: {extractTime(currentTime.startTime)}
              </div>
            </div>

            <button
              className="button is-rounded is-info is-inverted level-right"
              type="button"
              onClick={handleStop}
              aria-label="stop"
              title="stop"
            >
              <FontAwesomeIcon icon={faStop} />
              <span className="pl-2">
                {time && time.split(':')[0]}
                <span className="time-sep" />
                {time && time.split(':')[1]}
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentTime: state.time.currentTime,
});

const mapActionToProps = {
  fetchCurrentTime: time.fetchCurrent,
  updateTime: time.updateTime,
  stop: null,
};

export default connect(mapStateToProps, mapActionToProps)(TimeCurrent);
