import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import store from './actions/store';
import STORAGE from './helpers/storage';

// import { setCurrentUser } from './actions/auth';
import { setAuthToken, setCurrentUser } from './helpers/auth';

import PrivateRoute from './components/PrivateRoute';
import AuthRoute from './components/AuthRoute';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import IndexPage from './pages/IndexPage';
import ProjectListPage from './pages/ProjectListPage';
import ProjectPage from './pages/ProjectPage';
import TaskPage from './pages/TaskPage';
import ReportPage from './pages/ReportPage';

import './App.scss';

const token = STORAGE.getItem('jwtToken');

if (token && token !== 'undefined') {
  setAuthToken(token);

  const decoded = STORAGE.jwtDecode(token);

  store.dispatch(setCurrentUser(decoded));
}

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <AuthRoute exact path="/login" component={LoginPage} />
          <AuthRoute exact path="/register" component={RegisterPage} />

          <PrivateRoute exact path="/" component={IndexPage} />
          <PrivateRoute exact path="/project" component={ProjectListPage} />
          <PrivateRoute path="/project/:id" component={ProjectPage} />
          <PrivateRoute path="/task/:id" component={TaskPage} />
          <PrivateRoute path="/report" component={ReportPage} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
