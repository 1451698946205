import { UI_SHOW_MODAL, UI_CLOSE_MODAL } from '../actions/types';

const initialState = {
  modal: null,
};

const ui = (state = initialState, action) => {
  switch (action.type) {
    case UI_SHOW_MODAL:
      return {
        ...state,
        modal: action.payload,
      };

    case UI_CLOSE_MODAL:
      return {
        ...state,
        modal: initialState.modal,
      };

    default:
      return state;
  }
};

export default ui;
