import api from './api';

import {
  PROJECT_FETCH_ALL,
  PROJECT_FETCH_BY_ID,
  PROJECT_FETCH_TASKS,
  PROJECT_CREATE,
  PROJECT_UPDATE,
  PROJECT_DELETE,
} from './types';

export const fetchAllProjects = () => (dispatch) => {
  api
    .project()
    .fetchAll()
    .then((res) => {
      dispatch({
        type: PROJECT_FETCH_ALL,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchAllWithTasks = () => (dispatch) => {
  api
    .project()
    .fetchAllWithTasks()
    .then((res) => {
      dispatch({
        type: PROJECT_FETCH_TASKS,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchProject = (id) => (dispatch) => {
  api
    .project()
    .fetchById(id)
    .then((res) => {
      dispatch({
        type: PROJECT_FETCH_BY_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const createProject = (data, onSuccess) => (dispatch) => {
  api
    .project()
    .create(data)
    .then((res) => {
      dispatch({
        type: PROJECT_CREATE,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateProject = (id, data, onSuccess) => (dispatch) => {
  api
    .project()
    .update(id, data)
    .then((res) => {
      dispatch({
        type: PROJECT_UPDATE,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteProject = (id, onSuccess) => (dispatch) => {
  api
    .project()
    .delete(id)
    .then(() => {
      dispatch({
        type: PROJECT_DELETE,
        payload: id,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });
};
