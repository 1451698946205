import api from './api';

import {
  TIME_FETCH_ALL,
  TIME_FETCH_RECENT,
  TIME_FETCH_BY_USER,
  TIME_FETCH_BY_TASK,
  TIME_FETCH_CURRENT,
  TIME_CREATE,
  TIME_UPDATE,
  TIME_DELETE,
} from './types';

export const fetchAll = () => (dispatch) => {
  api
    .time()
    .fetchAll()
    .then((res) => {
      dispatch({
        type: TIME_FETCH_ALL,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchRecent = (userId) => (dispatch) => {
  api
    .time()
    .fetchRecent(userId)
    .then((res) => {
      dispatch({
        type: TIME_FETCH_RECENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchByUser = (userId) => (dispatch) => {
  api
    .time()
    .fetchByUser(userId)
    .then((res) => {
      dispatch({
        type: TIME_FETCH_BY_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchByTask = (taskId) => (dispatch) => {
  api
    .time()
    .fetchByTask(taskId)
    .then((res) => {
      dispatch({
        type: TIME_FETCH_BY_TASK,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchCurrent = (userId) => (dispatch) => {
  api
    .time()
    .fetchCurrent(userId)
    .then((res) => {
      dispatch({
        type: TIME_FETCH_CURRENT,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const createTime = (data, onSuccess) => (dispatch) => {
  api
    .time()
    .create(data)
    .then((res) => {
      dispatch({
        type: TIME_CREATE,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const updateTime = (id, data, onSuccess) => (dispatch) => {
  api
    .time()
    .update(id, data)
    .then((res) => {
      dispatch({
        type: TIME_UPDATE,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const deleteTime = (id, onSuccess) => (dispatch) => {
  api
    .time()
    .delete(id)
    .then(() => {
      dispatch({
        type: TIME_DELETE,
        payload: id,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });
};
