import { REPORT_BY_USER } from '../actions/types';

const initialState = {
  list: {},
};

const report = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_BY_USER:
      return {
        ...state,
        list: action.payload,
      };

    default:
      return state;
  }
};

export default report;
