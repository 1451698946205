import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { showModal } from '../actions/ui';
import * as project from '../actions/project';
import { parseDuration } from '../helpers';

const ProjectHeader = ({ fetchProject, projectItem, projectId, showModal }) => {
  useEffect(() => {
    fetchProject(projectId);
  }, [fetchProject, projectId]);

  const editHandler = () => {
    const modal = {
      type: 'PROJECT_EDIT_MODAL',
      project: projectItem,
    };

    showModal(modal);
  };

  return (
    <div className="box">
      {!!projectItem && (
        <>
          <div className="is-flex align-items-center">
            <b className="has-text-weight-bold is-size-4">{projectItem.name}</b>

            <div className="is-flex has-margin-left-auto">
              <button
                type="button"
                className="button is-small is-rounded ml-2"
                aria-label="Edit"
                onClick={editHandler}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
            </div>
          </div>

          <div className="is-family-code is-size-7">
            Total time: {parseDuration(projectItem.duration) || '00:00'}h
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  projectItem: state.project.projectItem,
  user: state.auth.user,
  currentTime: state.time.currentTime,
});

const mapActionToProps = {
  fetchProject: project.fetchProject,
  showModal,
};

export default connect(mapStateToProps, mapActionToProps)(ProjectHeader);
