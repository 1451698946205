import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as task from '../actions/task';

import TaskItem from './TaskItem';

const TaskList = ({ projectId, taskList, currentTime, fetchTimeByProject }) => {
  useEffect(() => {
    fetchTimeByProject(projectId);
  }, [fetchTimeByProject, projectId, currentTime]);

  return (
    <div className="box">
      <table className="table is-fullwidth is-hoverable">
        <tbody>
          {taskList.map((taskItem) => (
            <TaskItem key={taskItem._id} task={taskItem} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  taskList: state.task.projectTasks,
  currentTime: state.time.currentTime,
});

const mapActionToProps = {
  fetchTimeByProject: task.fetchByProject,
};

export default connect(mapStateToProps, mapActionToProps)(TaskList);
