import React from 'react';

import Navbar from './Navbar';
import TimeCurrent from './TimeCurrent';
import TimeForm from './TimeForm';
import Modal from './Modal';

const Layout = ({ children }) => (
  <div>
    <Navbar />
    <main className="py-4">
      <div className="container">
        <TimeCurrent />

        <TimeForm />

        {children}
      </div>
    </main>
    <Modal />
  </div>
);

export default Layout;
