import { combineReducers } from 'redux';

import auth from './auth';
import project from './project';
import task from './task';
import time from './time';
import report from './report';
import ui from './ui';

const reducers = combineReducers({
  auth,
  project,
  task,
  time,
  report,
  ui,
});

export default reducers;
