import {
  TIME_FETCH_ALL,
  TIME_FETCH_RECENT,
  TIME_FETCH_BY_USER,
  TIME_FETCH_BY_TASK,
  TIME_FETCH_CURRENT,
  TIME_CREATE,
  TIME_UPDATE,
  TIME_DELETE,
} from '../actions/types';

const initialState = {
  timeList: [],
  currentTime: {},
};

const time = (state = initialState, action) => {
  switch (action.type) {
    case TIME_FETCH_ALL:
    case TIME_FETCH_RECENT:
    case TIME_FETCH_BY_USER:
    case TIME_FETCH_BY_TASK:
      return {
        ...state,
        timeList: [...action.payload],
      };

    case TIME_FETCH_CURRENT:
      return {
        ...state,
        currentTime: { ...action.payload },
      };

    case TIME_CREATE:
      return {
        ...state,
        timeList: [...state.timeList, action.payload],
        currentTime: !action.payload.duration
          ? { ...action.payload }
          : state.currentTime,
      };

    case TIME_UPDATE:
      return {
        ...state,
        timeList: state.timeList.map((timeItem) =>
          timeItem._id === action.payload._id ? action.payload : timeItem,
        ),
        currentTime:
          state.currentTime._id === action.payload._id ? {} : state.currentTime,
      };

    case TIME_DELETE:
      return {
        ...state,
        timeList: state.timeList.filter(
          (timeItem) => timeItem._id !== action.payload,
        ),
      };

    default:
      return state;
  }
};

export default time;
