import api from './api';

import {
  TASK_FETCH_ALL,
  TASK_FETCH_BY_PROJECT,
  TASK_FETCH_BY_ID,
  TASK_CREATE,
  TASK_UPDATE,
  TASK_DELETE,
} from './types';

export const fetchAllTasks = () => (dispatch) => {
  api
    .task()
    .fetchAll()
    .then((res) => {
      dispatch({
        type: TASK_FETCH_ALL,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchByProject = (projectId) => (dispatch) => {
  api
    .task()
    .fetchByProject(projectId)
    .then((res) => {
      dispatch({
        type: TASK_FETCH_BY_PROJECT,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchById = (id) => (dispatch) => {
  api
    .task()
    .fetchById(id)
    .then((res) => {
      dispatch({
        type: TASK_FETCH_BY_ID,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const createTask = (data, onSuccess) => (dispatch) =>
  api
    .task()
    .create(data)
    .then((res) => {
      dispatch({
        type: TASK_CREATE,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });

export const updateTask = (id, data, onSuccess) => (dispatch) =>
  api
    .task()
    .update(id, data)
    .then((res) => {
      dispatch({
        type: TASK_UPDATE,
        payload: res.data,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });

export const deleteTask = (id, onSuccess) => (dispatch) => {
  api
    .task()
    .delete(id)
    .then(() => {
      dispatch({
        type: TASK_DELETE,
        payload: id,
      });
      if (onSuccess) onSuccess();
    })
    .catch((err) => {
      throw new Error(err);
    });
};
