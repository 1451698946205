import React, { useState } from 'react';

import { connect } from 'react-redux';
import * as task from '../actions/task';

const initialFieldValues = {
  name: '',
};

const TaskFrom = ({ createTask, projectId }) => {
  const [values, setValues] = useState(initialFieldValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const resetForm = () => {
    setValues(initialFieldValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!values.name || !projectId) return;

    const onSuccess = () => {
      resetForm();
    };

    values.project = projectId;

    createTask(values, onSuccess);
  };

  return (
    <div className="box">
      <form onSubmit={handleSubmit} className="is-flex">
        <div className="field" style={{ width: '200px' }}>
          <div>
            <label
              className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1"
              htmlFor="name"
            >
              Add new task
            </label>
          </div>

          <div className="field-body">
            <div className="field">
              <p className="control">
                <input
                  className="input"
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Task name"
                  value={values.name}
                  onChange={handleInputChange}
                />
              </p>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="button is-primary has-margin-top-auto mb-3 ml-2"
        >
          Add
        </button>
      </form>
    </div>
  );
};

const mapActionToProps = {
  createTask: task.createTask,
};

export default connect(null, mapActionToProps)(TaskFrom);
