import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { closeModal } from '../actions/ui';
import { updateProject, deleteProject } from '../actions/project';
import useForm from '../hooks/useForm';
import ModalWrapper from './ModalWrapper';

const ProjectEditModal = ({
  modal,
  updateProject,
  deleteProject,
  closeModal,
  history,
}) => {
  const { values, handleInputChange } = useForm({
    name: modal.project.name,
  });

  const handleSubmit = () => {
    if (!values.name || !modal.project._id) return;

    updateProject(modal.project._id, values, closeModal);
  };

  const handleDelete = () => {
    if (!modal.project._id) return;

    if (window.confirm('Are you sure you want to delete this project?'))
      deleteProject(modal.project._id, () => {
        closeModal();
        history.push(`/project`);
      });
  };

  return (
    <ProjectEditModalComponent
      values={values}
      inputChangeHandler={handleInputChange}
      submitHandler={handleSubmit}
      deleteHandler={handleDelete}
      closeModalHandler={closeModal}
    />
  );
};

const ProjectEditModalComponent = ({
  values,
  inputChangeHandler,
  submitHandler,
  deleteHandler,
  closeModalHandler,
}) => (
  <ModalWrapper title="Edit task">
    <section className="modal-card-body">
      <div className="field">
        <div>
          <label
            className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1"
            htmlFor="name"
          >
            Project name
          </label>
        </div>

        <div className="field-body">
          <div className="field">
            <p className="control">
              <input
                className="input"
                id="name"
                name="name"
                type="text"
                value={values.name}
                onChange={inputChangeHandler}
              />
            </p>
          </div>
        </div>
      </div>
    </section>

    <footer className="modal-card-foot">
      <button
        type="button"
        className="button is-success"
        onClick={submitHandler}
      >
        Save changes
      </button>

      <button type="button" className="button" onClick={closeModalHandler}>
        Cancel
      </button>

      <button
        type="button"
        className="button has-margin-left-auto is-danger"
        onClick={deleteHandler}
      >
        Delete project
      </button>
    </footer>
  </ModalWrapper>
);

const mapActionToProps = {
  updateProject,
  deleteProject,
  closeModal,
};

export default connect(null, mapActionToProps)(withRouter(ProjectEditModal));
