export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const TOKEN_REFRESH = 'TOKEN_REFRESH';

export const PROJECT_FETCH_ALL = 'PROJECT_FETCH_ALL';
export const PROJECT_FETCH_BY_ID = 'PROJECT_FETCH_BY_ID';
export const PROJECT_FETCH_TASKS = 'PROJECT_FETCH_TASKS';
export const PROJECT_CREATE = 'PROJECT_CREATE';
export const PROJECT_UPDATE = 'PROJECT_UPDATE';
export const PROJECT_DELETE = 'PROJECT_DELETE';

export const REPORT_BY_USER = 'REPORT_BY_USER';

export const TASK_FETCH_ALL = 'TASK_FETCH_ALL';
export const TASK_FETCH_BY_PROJECT = 'TASK_FETCH_BY_PROJECT';
export const TASK_FETCH_BY_ID = 'TASK_FETCH_BY_ID';
export const TASK_CREATE = 'TASK_CREATE';
export const TASK_UPDATE = 'TASK_UPDATE';
export const TASK_DELETE = 'TASK_DELETE';

export const TIME_FETCH_ALL = 'TIME_FETCH_ALL';
export const TIME_FETCH_RECENT = 'TIME_FETCH_RECENT';
export const TIME_FETCH_BY_USER = 'TIME_FETCH_BY_USER';
export const TIME_FETCH_BY_TASK = 'TIME_FETCH_BY_TASK';
export const TIME_FETCH_CURRENT = 'TIME_FETCH_CURRENT';
export const TIME_CREATE = 'TIME_CREATE';
export const TIME_UPDATE = 'TIME_UPDATE';
export const TIME_DELETE = 'TIME_DELETE';

export const UI_SHOW_MODAL = 'UI_SHOW_MODAL';
export const UI_CLOSE_MODAL = 'UI_CLOSE_MODAL';
