import api from './api';

import { REPORT_BY_USER } from './types';

export const byUser = (id, startDate, endDate) => (dispatch) => {
  api
    .report()
    .byUser(id, startDate, endDate)
    .then((res) => {
      dispatch({
        type: REPORT_BY_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const byProject = () => {};
