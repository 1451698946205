import api from './api';
import { setAuthToken } from '../helpers/auth';
import { USER_LOGIN, USER_LOGOUT, GET_ERRORS } from './types';
import STORAGE from '../helpers/storage';

export const registerUser = (userData) => (dispatch) => {
  api
    .auth()
    .registerUser(userData)
    .then((res) => {
      const { accessToken, refreshToken } = res.data;

      STORAGE.setItem('jwtToken', accessToken);
      STORAGE.setItem('jwtRefreshToken', refreshToken);

      setAuthToken(accessToken);

      const decoded = STORAGE.jwtDecode(accessToken);

      dispatch({
        type: USER_LOGIN,
        payload: decoded,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    );
};

export const loginUser = (userData) => (dispatch) => {
  api
    .auth()
    .loginUser(userData)
    .then((res) => {
      const { accessToken, refreshToken } = res.data;

      STORAGE.setItem('jwtToken', accessToken);
      STORAGE.setItem('jwtRefreshToken', refreshToken);

      setAuthToken(accessToken);

      const decoded = STORAGE.jwtDecode(accessToken);

      dispatch({
        type: USER_LOGIN,
        payload: decoded,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    );
};

export const logoutUser = () => (dispatch) => {
  api
    .auth()
    .logoutUser({ token: localStorage.getItem('jwtRefreshToken') })
    .then(() => {
      STORAGE.clear();

      setAuthToken(false);

      dispatch({
        type: USER_LOGOUT,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }),
    );
};
