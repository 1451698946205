import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as project from '../actions/project';

import Layout from '../components/Layout';

import ProjectList from '../components/ProjectList';
import ProjectForm from '../components/ProjectForm';

const ProjectListPage = ({ fetchAllProjects, projectList }) => {
  useEffect(() => {
    fetchAllProjects();
  }, [fetchAllProjects]);

  return (
    <Layout>
      <ProjectForm />

      <ProjectList projectList={projectList} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  projectList: state.project.projectList,
});

const mapActionToProps = {
  fetchAllProjects: project.fetchAllProjects,
};

export default connect(mapStateToProps, mapActionToProps)(ProjectListPage);
