import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import uuid from 'react-uuid';

import * as taskActions from '../actions/task';
import * as projectActions from '../actions/project';
import * as time from '../actions/time';

import 'react-datepicker/dist/react-datepicker.css';

import { parseDurationObj } from '../helpers';

import useForm from '../hooks/useForm';

const initialFieldValues = {
  date: Date.now(),
  task: '',
  start: new Date(),
  end: new Date(),
};

const TimeFrom = ({
  fetchAllWithTasks,
  projectsTasks,
  taskList,
  createTime,
  user,
  taskFetchComplete,
}) => {
  const {
    values,
    handleInputChange,
    handleDateChange,
    handleTimeChange,
    handleDurationChange,
  } = useForm(initialFieldValues);

  useEffect(() => {
    if (taskFetchComplete) return;

    fetchAllWithTasks();
  }, [fetchAllWithTasks, taskFetchComplete]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!values.task) {
      window.alert('Please select a task!');
      return;
    }

    if (values.end - values.start < 1) {
      window.alert('Incorrect duration!');
      return;
    }

    const startTime = new Date(values.date);
    startTime.setHours(values.start.getHours());
    startTime.setMinutes(values.start.getMinutes());

    const formData = {
      user: user.id,
      startTime,
      duration: values.end - values.start,
      task: values.task,
    };

    createTime(formData);
  };

  return (
    <div className="box">
      <div className="is-uppercase has-text-weight-bold is-size-7 pb-2">
        Add time
      </div>

      <form onSubmit={handleSubmit} className="is-flex">
        <div className="mr-2">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Task
          </div>
          <div className="select is-small">
            <select
              name="task"
              onChange={handleInputChange}
              value={values.task}
            >
              <option disabled value="">
                Select a task
              </option>

              {projectsTasks.map((project) => {
                return (
                  <optgroup label={project.name} key={project._id}>
                    {project.tasks &&
                      project.tasks.map((task) => {
                        return (
                          <option key={task._id} value={task._id}>
                            {task.name}
                          </option>
                        );
                      })}
                  </optgroup>
                );
              })}
            </select>
          </div>
        </div>

        <div className="mr-2">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Date
          </div>
          <DatePicker
            selected={values.date}
            onChange={(date) => handleDateChange('date', date)}
            name="date"
            dateFormat="d MMMM yyyy"
            customInput={
              <input
                id="date"
                className="input is-small"
                type="text"
                placeholder="Text input"
              />
            }
          />
        </div>

        <div className="mr-4">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Start
          </div>

          <div className="is-flex">
            <div className="select is-small">
              <select
                name="startHour"
                onChange={handleTimeChange}
                value={values.start.getHours()}
              >
                {[...Array(24)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <span className="px-1">:</span>

            <div className="select is-small">
              <select
                name="startMin"
                onChange={handleTimeChange}
                value={values.start.getMinutes()}
              >
                {[...Array(60)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mr-4">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Finish
          </div>

          <div className="is-flex">
            <div className="select is-small">
              <select
                name="endHour"
                onChange={handleTimeChange}
                value={values.end.getHours()}
              >
                {[...Array(24)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <span className="px-1">:</span>

            <div className="select is-small">
              <select
                name="endMin"
                onChange={handleTimeChange}
                value={values.end.getMinutes()}
              >
                {[...Array(60)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mr-4">
          <div className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1">
            Duration
          </div>

          <div className="is-flex">
            <div className="select is-small">
              <select
                name="durationHour"
                onChange={handleDurationChange}
                value={parseInt(
                  parseDurationObj(values.end - values.start).h,
                  10,
                )}
              >
                {[...Array(24)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <span className="px-1">:</span>

            <div className="select is-small">
              <select
                name="durationMin"
                onChange={handleDurationChange}
                value={parseInt(
                  parseDurationObj(values.end - values.start).m,
                  10,
                )}
              >
                {[...Array(60)].map((e, i) => (
                  <option key={uuid()} value={i}>
                    {i < 10 && '0'}
                    {i}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="button is-primary is-small has-margin-top-auto"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const mapActionToProps = {
  createTask: taskActions.createTask,
  fetchAllWithTasks: projectActions.fetchAllWithTasks,
  createTime: time.createTime,
};

const mapStateToProps = (state) => ({
  taskList: state.task.allTasks,
  user: state.auth.user,
  projectsTasks: state.project.projectsTasks,
  taskFetchComplete: state.project.taskFetchComplete,
});

export default connect(mapStateToProps, mapActionToProps)(TimeFrom);
