import React from 'react';
import { Link } from 'react-router-dom';
import { parseDuration } from '../helpers';

const ProjectList = ({ projectList }) => {
  return (
    <div className="box">
      <table className="table is-fullwidth is-hoverable">
        <tbody>
          {projectList.map((project) => {
            return (
              <tr key={project._id}>
                <td>
                  <Link to={`/project/${project._id}`}>
                    <b>{project.name}</b>
                  </Link>
                </td>
                <td>
                  <div className="is-flex align-items-center justify-flex-end">
                    {parseDuration(project.duration) || '00:00'}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectList;
