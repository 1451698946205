import { useState } from 'react';

const useForm = (initialFieldValues) => {
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDateChange = (name, date) => {
    setValues({
      ...values,
      [name]: date,
    });
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;

    const keyName = name.includes('start') ? 'start' : 'end';

    const nextTime = values[keyName];

    if (name.includes('Hour')) nextTime.setHours(value);
    if (name.includes('Min')) nextTime.setMinutes(value);

    setValues({
      ...values,
      [keyName]: nextTime,
    });
  };

  const handleDurationChange = (e) => {
    const { name, value } = e.target;

    const nextEnd = values.end;

    if (name === 'durationHour')
      nextEnd.setHours(values.start.getHours() + parseInt(value, 10));
    if (name === 'durationMin')
      nextEnd.setMinutes(values.start.getMinutes() + parseInt(value, 10));

    setValues({
      ...values,
      end: nextEnd,
    });
  };

  const resetForm = () => {
    setValues(initialFieldValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleDateChange,
    handleTimeChange,
    handleDurationChange,
    resetForm,
  };
};

export default useForm;
