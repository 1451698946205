import React from 'react';
import { connect } from 'react-redux';

import TaskEditModal from './TaskEditModal';
import TimeEditModal from './TimeEditModal';
import ProjectEditModal from './ProjectEditModal';

const Modal = ({ modal }) => {
  return (
    <>
      {modal &&
        modal.type &&
        {
          TASK_EDIT_MODAL: <TaskEditModal modal={modal} />,
          TIME_EDIT_MODAL: <TimeEditModal modal={modal} />,
          PROJECT_EDIT_MODAL: <ProjectEditModal modal={modal} />,
        }[modal.type]}
    </>
  );
};

const mapStateToProps = (state) => ({
  modal: state.ui.modal,
});

export default connect(mapStateToProps)(Modal);
