import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRoute = ({ component: Component, auth, location, ...rest }) => {
  const { from } = location.state || { from: { pathname: '/' } };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Route
      {...rest}
      render={({ ...props }) =>
        auth.isAuthenticated === true ? (
          <Redirect to={from} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AuthRoute);
