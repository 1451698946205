import jwtDecode from 'jwt-decode';

class STORAGE {
  static setItem = (key, value) => localStorage.setItem(key, value);

  static getItem = (key) => localStorage.getItem(key);

  static removeItem = (key) => localStorage.removeItem(key);

  static clear = () => localStorage.clear();

  static jwtDecode = (token) => jwtDecode(token);
}

export default STORAGE;
