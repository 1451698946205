import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { showModal } from '../actions/ui';
import * as time from '../actions/time';

import {
  extractDate,
  extractTime,
  getEndTime,
  parseDuration,
} from '../helpers';

const TaskTimeList = ({ timeList, fetchTimeByTask, taskId, showModal }) => {
  useEffect(() => {
    fetchTimeByTask(taskId);
  }, [fetchTimeByTask, taskId]);

  const handleEdit = (timeItem) => {
    const modal = {
      type: 'TIME_EDIT_MODAL',
      timeItem,
    };

    showModal(modal);
  };

  return (
    <>
      <div className="box">
        <table className="table is-fullwidth is-hoverable">
          <tbody>
            {timeList
              .sort((a, b) =>
                Date.parse(a.startTime) < Date.parse(b.startTime) ? 1 : -1,
              )
              .map((timeItem) => (
                <tr key={timeItem._id}>
                  <td width="160px">{extractDate(timeItem.startTime)}</td>

                  <td>
                    {extractTime(timeItem.startTime)} -{' '}
                    {getEndTime(timeItem.startTime, timeItem.duration)}
                  </td>

                  <td className="has-text-right">
                    <div className="is-flex align-items-center justify-flex-end">
                      <b>{parseDuration(timeItem.duration)}</b>

                      {timeItem.duration && (
                        <button
                          type="button"
                          className="button is-small is-rounded ml-2"
                          aria-label="edit"
                          onClick={() => handleEdit(timeItem)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  timeList: state.time.timeList,
});

const mapActionToProps = {
  fetchTimeByTask: time.fetchByTask,
  showModal,
};

export default connect(mapStateToProps, mapActionToProps)(TaskTimeList);
