import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const parseDuration = (duration) => {
  if (!duration) return '';

  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const humanDuration = `${(hours < 10 ? '0' : '') + hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;

  return humanDuration;
};

export const parseDurationObj = (duration = 0) => {
  // if (!duration) return {m:0};

  const hours = Math.floor(duration / (1000 * 60 * 60));
  const minutes = Math.floor((duration / (1000 * 60)) % 60);

  const durationObj = {
    h: String(hours).padStart(2, '0'),
    m: String(minutes).padStart(2, '0'),
  };

  return durationObj;
};

export const extractDate = (date) => {
  const dateObj = new Date(date);
  const month = new Intl.DateTimeFormat('en-EN', { month: 'long' }).format(
    dateObj,
  );

  return `${dateObj.getDate()} ${month} ${dateObj.getFullYear()}`;
};

export const extractTime = (date) => {
  const dateObj = new Date(date);
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  return `${(hours < 10 ? '0' : '') + hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;
};

export const getEndTime = (startTime, duration) => {
  if (!duration) return ' __:__';

  const newDateTime = new Date(Date.parse(startTime) + duration);

  const hours = newDateTime.getHours();
  const minutes = newDateTime.getMinutes();

  return `${(hours < 10 ? '0' : '') + hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;
};

export const getCurrentUser = () => {
  let currentUser = {};

  const jwtToken = cookies.get('jwtToken');

  if (jwtToken) {
    const token = jwtToken;
    const decoded = jwtDecode(token);

    currentUser = {
      id: decoded.id,
      name: decoded.name,
    };
  }

  return currentUser;
};
