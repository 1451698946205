import {
  PROJECT_FETCH_ALL,
  PROJECT_FETCH_BY_ID,
  PROJECT_FETCH_TASKS,
  PROJECT_CREATE,
  PROJECT_UPDATE,
  PROJECT_DELETE,
} from '../actions/types';

const initialState = {
  projectList: [],
  projectsTasks: [],
  projectItem: null,
  taskFetchComplete: false,
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_FETCH_ALL:
      return {
        ...state,
        projectList: [...action.payload],
      };

    case PROJECT_FETCH_BY_ID:
      return {
        ...state,
        projectItem: { ...action.payload },
      };

    case PROJECT_FETCH_TASKS:
      return {
        ...state,
        projectsTasks: [...action.payload],
        emptyTest: [...action.payload],
        taskFetchComplete: true,
      };

    case PROJECT_CREATE:
      return {
        ...state,
        projectList: [...state.projectList, action.payload],
      };

    case PROJECT_UPDATE:
      return {
        ...state,
        projectItem: { ...state.projectItem, ...action.payload },
      };

    case PROJECT_DELETE:
      return {
        ...state,
        projectList: state.projectList.filter((x) => x._id !== action.payload),
        projectsTasks: state.projectsTasks.filter(
          (x) => x._id !== action.payload,
        ),
      };

    default:
      return state;
  }
};

export default project;
