import React from 'react';

import Layout from '../components/Layout';

import TaskTimeList from '../components/TaskTimeList';
import TaskItemHeader from '../components/TaskItemHeader';

const TaskPage = ({ match }) => {
  const taskId = match.params.id;

  return (
    <Layout>
      <TaskItemHeader taskId={taskId} />
      <TaskTimeList taskId={taskId} />
    </Layout>
  );
};

export default TaskPage;
