import React from 'react';

import Layout from '../components/Layout';

import ReportList from '../components/ReportList';

const ReportPage = () => {
  return (
    <Layout>
      <ReportList />
    </Layout>
  );
};

export default ReportPage;
