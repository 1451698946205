import React from 'react';

import Layout from '../components/Layout';

import ProjectHeader from '../components/ProjectHeader';

import TaskList from '../components/TaskList';
import TaskForm from '../components/TaskForm';

const ProjectPage = ({ match }) => {
  const projectId = match.params.id;

  return (
    <Layout>
      <ProjectHeader projectId={projectId} />

      <TaskForm projectId={projectId} />

      <TaskList projectId={projectId} />
    </Layout>
  );
};

export default ProjectPage;
