import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import uuid from 'react-uuid';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

import * as report from '../actions/report';

import { parseDuration } from '../helpers';

const initialRange = {
  from: undefined,
  to: undefined,
};

const ReportList = ({ list, user, byUser }) => {
  let toRef = useRef(null);

  const [range, setRange] = useState(initialRange);

  const modifiers = { start: range.from, end: range.to };

  const handleSubmit = () => {
    if (!range.from || !range.to) return alert('Please select time range!');

    const startTime = new Date(range.from.setHours(0, 0, 0, 0)).toISOString();

    const endTime = new Date(range.to.setHours(23, 59, 59, 999)).toISOString();

    byUser(user.id, startTime, endTime);
  };

  const handleFromChange = (from) => {
    setRange((initRange) => ({
      ...initRange,
      from,
    }));
  };

  const handleToChange = (to) => {
    setRange((initRange) => ({
      ...initRange,
      to,
    }));
  };

  return (
    <div>
      <div className="box is-flex">
        <div className="mr-2">
          <label
            htmlFor="from"
            className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1"
          >
            From
          </label>

          <DayPickerInput
            value={range.from}
            placeholder="From"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [range.from, { from: range.from, to: range.to }],
              disabledDays: { after: range.to },
              toMonth: range.to,
              modifiers,
              numberOfMonths: 2,
              onDayClick: () => toRef.getInput().focus(),
            }}
            onDayChange={handleFromChange}
            inputProps={{ className: 'input is-small' }}
          />
        </div>

        <div className="mr-2">
          <label
            htmlFor="from"
            className="is-block is-size-7 is-uppercase has-text-weight-bold mb-1"
          >
            To
          </label>

          <DayPickerInput
            ref={(c) => {
              toRef = c;
            }}
            value={range.to}
            placeholder="To"
            format="LL"
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [range.from, { from: range.from, to: range.to }],
              disabledDays: { before: range.from },
              modifiers,
              month: range.from,
              fromMonth: range.from,
              numberOfMonths: 2,
            }}
            onDayChange={handleToChange}
            inputProps={{ className: 'input is-small' }}
          />
        </div>

        <button
          type="button"
          onClick={handleSubmit}
          className="button is-primary is-small has-margin-top-auto"
        >
          Generate report
        </button>
      </div>

      {list.tasks && (
        <div className="box">
          <table className="table is-fullwidth is-hoverable">
            <tbody>
              <tr>
                <td colSpan="2">
                  <div className="is-flex justify-flex-end">
                    <b>Total: {parseDuration(list.duration) || '0:00'}</b>
                  </div>
                </td>
              </tr>
              {list.tasks.map((task) => {
                return <ProjectItem key={uuid()} task={task} />;
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const ProjectItem = ({ task }) => {
  return (
    <tr>
      <td>
        <Link to={`/project/${task.projectId}`}>
          <b>{task.projectName}</b>
        </Link>{' '}
        -{' '}
        <Link to={`/task/${task.taskId}`}>
          <b>{task.taskName}</b>
        </Link>
      </td>

      <td>
        <div className="is-flex justify-flex-end">
          {parseDuration(task.duration)}
        </div>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  list: state.report.list,
  user: state.auth.user,
});

const mapActionToProps = {
  byUser: report.byUser,
};

export default connect(mapStateToProps, mapActionToProps)(ReportList);
