import {
  TASK_FETCH_ALL,
  TASK_FETCH_BY_PROJECT,
  TASK_FETCH_BY_ID,
  TASK_CREATE,
  TASK_UPDATE,
  TASK_DELETE,
} from '../actions/types';

const initialState = {
  allTasks: [],
  projectTasks: [],
  taskList: [],
  taskItem: null,
};

const task = (state = initialState, action) => {
  switch (action.type) {
    case TASK_FETCH_ALL:
      return {
        ...state,
        allTasks: [...action.payload],
        // taskList: [...action.payload],
      };

    case TASK_FETCH_BY_PROJECT:
      return {
        ...state,
        projectTasks: [...action.payload],
        // taskList: [...action.payload],
      };

    case TASK_FETCH_BY_ID:
      return {
        ...state,
        taskItem: { ...action.payload },
      };

    case TASK_CREATE:
      return {
        ...state,
        // taskList: [...state.taskList, action.payload],
        projectTasks: [...state.projectTasks, action.payload],
      };

    case TASK_UPDATE:
      return {
        ...state,
        taskItem: { ...state.taskItem, ...action.payload },
      };

    case TASK_DELETE:
      return {
        ...state,
        taskList: state.taskList.filter(
          (taskItem) => taskItem._id !== action.payload,
        ),
        allTasks: state.taskList.filter(
          (taskItem) => taskItem._id !== action.payload,
        ),
      };

    default:
      return state;
  }
};

export default task;
