import axios from 'axios';
import { SET_CURRENT_USER } from '../actions/types';

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setCurrentUser = (decodedToken) => (dispatch) => {
  return dispatch({
    type: SET_CURRENT_USER,
    payload: decodedToken,
  });
};
